import React from 'react';
import "./description.css";

const Description = ({ name, description }) => {
  return (
    <div className="description-container col-5">
      <h2>{name}</h2>
      <p>{description}</p>
    </div>
  );
};

export default Description;
