import React from 'react';
import Description from './Description'; // Import the Description component
import Ingredients from './Ingredients'; // Import the Ingredients component
//import IngredientRow from './IngredientRow'; // Import the IngredientRow component
import "./recipe.css"

const Recipe = ({ recipeData }) => {
  const {
    name,
    imageUrl,
    description,
    recepieRows,
  } = recipeData;

  return (
    <div className="recipe-container">
      {imageUrl === null || imageUrl === '' ? (
        <img src="https://southernspicesrestaurant.com/img/placeholders/comfort_food_placeholder.png?v=1" alt="placeholder" className="recipe-image col-2" />
      ) : (
        <img src={imageUrl} alt={name} className="recipe-image col-2" />
        //Build error Line 18:9:  Redundant alt attribute. Screen-readers already announce `img` tags as an image. You don�t need to use the words `image`, `photo,` or `picture` (or any specified custom words) in the alt prop  jsx-a11y/img-redundant-alt
      )}
      
      <Description name={name} description={description} />
      <Ingredients recepieRows={recepieRows} />
    </div>
  );
};

export default Recipe;
