import React from 'react';
import IngredientRow from './IngredientRow'; // Import the IngredientRow component
import "./ingredients.css";

const Ingredients = ({ recepieRows }) => {
  return (
    <div className="ingredients-container col-5">
      <h3>Ingredients</h3>
      {recepieRows.map((row, index) => (
        <IngredientRow
          key={index}
          ingredient={row.ingredientName}  // Make sure to use the correct property
          amount={row.ingredientAmount}
          unit={row.ingredientUnitName}  // Make sure to use the correct property
        />
      ))}
    </div>
  );
};

export default Ingredients;
