import React from "react";

const IngredientRow = ({ ingredient, amount, unit }) => {
  return (
    <div className="ingredient-row">
      <span>{ingredient}</span>
      <span>{amount}</span>
      <span>{unit}</span>
    </div>
  );
};

export default IngredientRow;
