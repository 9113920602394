import React, { useState } from 'react';
import RecipeList from './RecipeList'; // Import the Recipe component

function FetchDataComponent() {
    const [input, setInput] = useState("Kyckling, Grädde");
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    //const baseUrl = "https://postgresapi.naterman.se/React/IngredientSearch?take=2&ingredient=";
    const baseUrl = "https://postgresapi.naterman.se/React/MultiIngredientSearch?take=2&ingredients=";

    const fetchData = () => {
        const url = baseUrl + encodeURIComponent(input);
        setLoading(true);
        setError(null);

        fetch(url, {
            method: 'GET',
            headers: {
                'CF-Access-Client-Id': '8a812d45c68be5104622d7dfe0f65509.access',
                'CF-Access-Client-Secret': 'fe8c8f919d9434f44c91e0ebcd537ee1abb04d000bf2b62095ba2518071e5067'
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setData(data);
            setLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setLoading(false);
        });
    };

    return (
        <div>
            <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Enter ingredient"
            />
            <button onClick={fetchData} disabled={loading}>
                Search Ingredient
            </button>

            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {data && (
                <div>
                    {/* Display the RecipeList component with the fetched data*/}
                    <RecipeList recipeData={data} />
                </div>
            )}
        </div>
    );
}

export default FetchDataComponent;
