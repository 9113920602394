import React from 'react';
import Recipe from './Recipe';
import "./recipeList.css";

const RecipeList = ({ recipeData }) => {
  return (
    <div className="recipeList-container">
      {recipeData.map((recipe, index) => (
        <Recipe
          key = {index}
          recipeData = {recipe}
        />
      ))}
    </div>
  );
};

export default RecipeList;
